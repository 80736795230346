if ($(window).width() > 1024) { 
    //HOME PAGE SECTION SCROLL
    let myFullpage = new fullpage('#fullpage', {
        verticalCentered: false,
        css3:false,
        anchors: ['firstPage', 'secondPage'],
        menu: '#menu',
        lazyLoad: true,
    });
}else{
}

//BACKGROUNT IMAGE
$(".div-bg").each( function(){
    var imageUrl = $(this).find('img').attr("src");			
    $(this).find('img').css("visibility","hidden");			
    $(this).css('background-image', 'url(' + imageUrl + ')').css("background-repeat","no-repeat").css("background-size","cover").css("background-position","50% 50%");		
});

$( document ).ready(function() {
    //CONTACT FORM
    $('.controlElement').each(function(){
        if ($(this).val()){
        $(this).closest('.formGroup').addClass("quote");
        }
    });
    $(".formGroup .controlElement").on('keyup focusout',function() {
        if ($(this).val().length === 0) {
            $(this).closest('.formGroup').removeClass('quote').removeClass('active-form');
        } else {
            $(this).closest('.formGroup').addClass('quote').addClass('active-form');
        }
    });
    $('.textClear').on('click', function (e){
        e.preventDefault();
        $(this).prev('.controlElement').val('');
        $(this).closest('.formGroup').removeClass('quote').removeClass('active-form');
    });
    // $('#sendBtn').on('click', function (){
    //     if ($('.controlElement').val()){
    //         $('#sendBtn').addClass('sending');
    //     } else {
    //         $('#sendBtn').removeClass('sending');
    //     }
    // });
    //FIX HEIGHT
    let vidHeight = $('.videoHold').outerHeight();
    $('.templateMainHolder').css("min-height", vidHeight);

    //PAGE PREE LOADER
    setTimeout(function(){
        $('.tempLoader').addClass('hide')
    }, 1500);
    
    setTimeout(function(){
        $('.tempLoader').fadeOut('hide')
    }, 1700);

    //NAV MENU
    $('.hamburger').click(function(){
        $('.navHolder').toggleClass('openNav');
        $(this).toggleClass('close');
        $('header').toggleClass('setNav');

    });
    // $('.closeBtn').click(function(){
    //     $('.navHolder.openNav').removeClass('openNav');
    // });
    
    $('.fancybox-container').click(function(e){
		e.stopPropagation();
    });
    $(".wVideo").fancybox();
});

$(window).on("load",function(){
    //HOME PAGE SCROLL
    if($(window).width() > 991){
        $("#content-3").mCustomScrollbar({
            scrollButtons:{enable:true},
            theme:"light-thick",
            scrollbarPosition:"outside"
        });
    }
    //HOVER FUNCTION
    $(".splitList").hover(function () {
        $(this).toggleClass("resultHover");
        $(this).siblings().toggleClass("resultSib");
    });

    //ISOTOP TAB
    // init Isotope
    var $grid = $('.tabgrid').isotope({
        itemSelector: '.element-item',
        layoutMode: 'fitRows',
    });
    
    // filter functions
    var filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function() {
            var number = $(this).find('.number').text();
            return parseInt( number, 10 ) > 50;
        },
        // show if name ends with -ium
        ium: function() {
            var name = $(this).find('.name').text();
            return name.match( /ium$/ );
        }
    };
    if($('.filters-button-group li').hasClass('is-checked')){
        var filterValue = $( this ).attr('data-filter');
        // use filterFn if matches value
        filterValue = filterFns[ filterValue ] || filterValue;
        $grid.isotope({ filter: filterValue });
    }
    // bind filter button click
    $('.filters-button-group').on( 'click', 'li', function() {
        var filterValue = $( this ).attr('data-filter');
        // use filterFn if matches value
        filterValue = filterFns[ filterValue ] || filterValue;
        $grid.isotope({ filter: filterValue });
    });  

    // change is-checked class on buttons
    $('.button-group').each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'li', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });
});

$('.filters-button-group li').click (function(){
    var tabId = $(this).attr('data-tab');
    $('.filters-button-group li').removeClass('is-checked');
    $('.tabList').removeClass('active');
    $(this).addClass('is-checked');
    $("#"+tabId).addClass('active');
 });

// var bannerPosition = $(window).scrollTop();

// $(window).scroll(function() {
//     var scroll = $(window).scrollTop();
//     if(scroll > bannerPosition) {
//         $('.templateListHolder').addClass('up');
//     } else {
//         $('.templateListHolder').removeClass('up');
//     }
//     bannerPosition = scroll;
// });

//var scroll = $(window).scrollTop();

var vidSmHeight = $('.videoSmHold').outerHeight;
$(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 120) {
        $('.widgetMudeRow').fadeOut();
    } else {
        $('.widgetMudeRow').fadeIn();
    }
});

$( document ).ready(function() {
    $('.eLoader').removeClass('eLoader');
    setTimeout(function(){
        $('figure.secLoader').removeClass('secLoader');
    }, 500);
});  